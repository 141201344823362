<template>
  <div>
    <template v-if="isEdit">
      <slot name="onEdit"></slot>
    </template>

    <template v-else>
      <slot name="onView"></slot>
    </template>
  </div>
</template>

<script>
export default {
  name: "OnEditRow",
  props: {
    isEdit: {
      type: Boolean,
      default: false
    }
  }
};
</script>
