<template>
  <div>
    <div class="card card-custom p-5 gutter-b">
      <div class="card-body p-0">
        <div class="row">
          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Code</label>

            <b-form-select
              :options="formattedProjectOptions"
              size="lg"
              v-model="form.project.code"
              @change="setProject($event)"
            ></b-form-select>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Nickname</label>

            <b-form-input
              id="input-large"
              size="lg"
              v-model="form.project.nickname"
              disabled
            ></b-form-input>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Meeting Number</label>

            <b-form-input
              id="input-large"
              size="lg"
              v-model="form.code"
            ></b-form-input>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Date</label>

            <b-form-input
              id="input-large"
              size="lg"
              type="datetime-local"
              v-model="form.date"
            ></b-form-input>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Agenda</label>

            <b-form-input
              id="input-large"
              size="lg"
              v-model="form.subject"
            ></b-form-input>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Leader</label>

            <b-form-input
              id="input-large"
              size="lg"
              v-model="form.leader"
            ></b-form-input>
          </div>

          <div class="col-md-12 py-2 col-sm-12">
            <label for="input-large">Place</label>

            <b-form-input
              id="input-large"
              size="lg"
              v-model="form.place"
            ></b-form-input>
          </div>

          <div class="col-md-12 py-2 col-sm-12">
            <label for="input-large">Status</label>

            <b-form-select
              v-model="form.status"
              :options="statusOptions"
              size="lg"
            ></b-form-select>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <b-button
              class="ml-auto mr-2"
              variant="success"
              :disabled="loading"
              @click="save"
            >
              <i class="flaticon2-check-mark"></i> Save
            </b-button>

            <b-button
              class="ml-auto mr-2"
              variant="secondary"
              :disabled="loading"
              @click="toIndexPage"
            >
              <i class="flaticon2-cancel-music"></i> Cancel
            </b-button>
          </div>
        </div>
      </div>
    </div>

    <div class="card card-custom p-5 gutter-b">
      <div class="card-header p-0">
        <div class="card-title">
          <h3 class="card-label">
            Meeting Invitation
          </h3>
        </div>
      </div>

      <div class="card-body px-0">
        <div class="d-flex mb-5">
          <b-button
            class="mr-2"
            variant="success"
            :disabled="loading"
            @click="addParticipant"
          >
            <i class="flaticon2-plus"></i> Add Participant
          </b-button>
        </div>

        <complete-table :data="attendant" :loading="loading">
          <template #header>
            <tr class="text-left">
              <th class="pl-7">Name</th>
              <th class="pl-7">Email</th>
              <th class="pl-7">Departement</th>
              <th class="pl-7">Division</th>
              <th class="pl-7 text-center">Action</th>
            </tr>
          </template>

          <template #defaultBody>
            <template
              v-for="(item, i) in attendant.filter((c) => c.delete != 1)"
            >
              <tr v-bind:key="i" class="border-0">
                <td class="pl-0 pt-8">
                  <on-edit-row :is-edit="isEdit(i)">
                    <template #onView>
                      <span
                        class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                      >
                        {{ item.name }}
                      </span>
                    </template>

                    <template #onEdit>
                      <b-form-input
                        id="input-large"
                        size="lg"
                        v-model="item.name"
                      ></b-form-input>
                    </template>
                  </on-edit-row>
                </td>

                <td class="pl-0 pt-8">
                  <on-edit-row :is-edit="isEdit(i)">
                    <template #onView>
                      <span
                        class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                      >
                        {{ item.email }}
                      </span>
                    </template>

                    <template #onEdit>
                      <b-form-input
                        id="input-large"
                        size="lg"
                        v-model="item.email"
                      ></b-form-input>
                    </template>
                  </on-edit-row>
                </td>

                <td class="pl-0 pt-8">
                  <on-edit-row :is-edit="isEdit(i)">
                    <template #onView>
                      <span
                        class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                      >
                        {{ item.department ? item.department.name : "-" }}
                      </span>
                    </template>

                    <template #onEdit>
                      <b-form-select
                        :options="formattedDepartmentOptions"
                        size="lg"
                        v-model="item.department.code"
                        @change="
                          setDataByIndex(
                            $event,
                            'department',
                            i,
                            formattedDepartmentOptions
                          )
                        "
                      ></b-form-select>
                    </template>
                  </on-edit-row>
                </td>

                <td class="pl-0 pt-8">
                  <on-edit-row :is-edit="isEdit(i)">
                    <template #onView>
                      <span
                        class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                      >
                        {{ item.division ? item.division.name : "-" }}
                      </span>
                    </template>

                    <template #onEdit>
                      <b-form-select
                        :options="formattedDivisionOptions"
                        size="lg"
                        v-model="item.division.code"
                        @change="
                          setDataByIndex(
                            $event,
                            'division',
                            i,
                            formattedDivisionOptions
                          )
                        "
                      ></b-form-select>
                    </template>
                  </on-edit-row>
                </td>

                <td class="pl-0 pt-8">
                  <on-edit-row :is-edit="isEdit(i)">
                    <template #onEdit>
                      <a
                        class="btn btn-light-danger font-weight-bolder font-size-sm"
                        @click="cancelEditParticipant"
                      >
                        <i class="flaticon2-cancel-music"></i>
                      </a>
                    </template>

                    <template #onView>
                      <a
                        class="btn btn-light-success font-weight-bolder font-size-sm mr-2"
                        @click="editParticipant(item, i)"
                      >
                        <i class="flaticon-edit"></i>
                      </a>

                      <a
                        class="btn btn-light-danger font-weight-bolder font-size-sm mr-2"
                        @click="removeParticipant(i)"
                      >
                        <i class="flaticon2-rubbish-bin-delete-button"></i>
                      </a>
                    </template>
                  </on-edit-row>
                </td>
              </tr>
            </template>
          </template>
        </complete-table>

        <div class="d-flex justify-content-end mt-5">
          <div>
            <b-button
              class="ml-auto mr-2"
              variant="success"
              :disabled="loading"
              @click="saveParticipant"
            >
              <i class="flaticon2-check-mark"></i> Save
            </b-button>

            <b-button
              class="mr-2"
              variant="primary"
              @click="inviteParticipant"
              :disabled="loading"
            >
              <i class="flaticon2-paperplane"></i> Submit Invitation
            </b-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapState } from "vuex";
import { GET_PROJECT_DETAIL } from "../../../../core/services/store/project.module";
import { arrayMoreThanOne } from "../../../../core/helper/array-validation.helper";
import {
  GET_MEETING_BY_ID,
  INVITE_PARTICIPANT,
  SAVE_MEETING_PARTICIPANT,
  UPDATE_MEETING,
} from "../../../../core/services/store/meeting.module";
import CompleteTable from "../../../content/widgets/advance-table/CompleteTable";
import OnEditRow from "../../../content/widgets/advance-table/row/OnEditRow";
import { GET_DEPARTMENT } from "../../../../core/services/store/department.module";
import { GET_DIVISION } from "../../../../core/services/store/division.module";
import { deleteModal } from "../../../../core/helper/toast.helper";

export default {
  name: "MeetingUpdate",
  components: {
    OnEditRow,
    CompleteTable,
  },
  data() {
    return {
      form: {
        project: {
          code: null,
          nickname: null,
        },
        code: null,
        date: null,
        place: null,
        subject: null,
        pic: null,
        leader: null,
        division: null,
        department: null,
        status: null,
      },
      statusOptions: [
        {
          text: "Choose Status",
          value: null,
        },
        {
          text: "Open",
          value: "open",
        },
        {
          text: "Close",
          value: "close",
        },
      ],
      attendant: [],
      selectedParticipant: {
        id: null,
      },
      editIndex: null,
    };
  },

  computed: {
    ...mapState({
      loading: (state) => state.meeting.loading,
      projects: (state) => state.projectDetails.projectDetails?.data ?? [],
      departments: (state) => state.department.departments?.data ?? [],
      divisions: (state) => state.division.divisions?.data ?? [],
      meeting: (state) => state.meeting.meeting?.data,
    }),

    formattedProjectOptions() {
      let projects = [];

      if (arrayMoreThanOne(this.projects)) {
        projects = this.projects.map((project) => {
          return {
            value: project.code,
            text: project.code,
          };
        });
      }

      return [{ value: null, text: "Choose Project" }, ...projects];
    },

    formattedDepartmentOptions() {
      let departments = [];

      if (arrayMoreThanOne(this.departments)) {
        departments = this.departments.map((department) => {
          return {
            value: department.code,
            text: department.name,
          };
        });
      }

      return [{ value: null, text: "Choose Departemen" }, ...departments];
    },

    formattedDivisionOptions() {
      let divisions = [];

      if (arrayMoreThanOne(this.divisions)) {
        divisions = this.divisions.map((division) => {
          return {
            value: division.code,
            text: division.name,
          };
        });
      }

      return [{ value: null, text: "Choose Divisi" }, ...divisions];
    },
  },

  watch: {
    meeting() {
      Object.assign(this.form, {
        project: {
          code: this.meeting?.meeting?.project?.code,
          nickname: this.meeting?.meeting?.project?.nickname,
        },
        code: this.meeting?.meeting?.code,
        date: this.meeting?.meeting?.date?.replace(" ", "T"),
        place: this.meeting?.meeting?.place,
        subject: this.meeting?.meeting?.subject,
        pic: this.meeting?.meeting?.pic,
        leader: this.meeting?.meeting?.leader,
        division: this.meeting?.meeting?.division,
        department: this.meeting?.meeting?.department,
        status: this.meeting?.meeting?.status,
      });

      this.attendant = this.meeting?.attendant;
    },
  },

  methods: {
    getProjects() {
      this.$store.dispatch(GET_PROJECT_DETAIL, {
        jobdesk: "design",
        params: ["interface", "design", "admin"],
      });
    },

    getDepartments() {
      if (!arrayMoreThanOne(this.departments)) {
        this.$store.dispatch(GET_DEPARTMENT, {});
      }
    },

    getDivisions() {
      if (!arrayMoreThanOne(this.divisions)) {
        this.$store.dispatch(GET_DIVISION, {});
      }
    },

    getMeetingDetail() {
      this.$store.dispatch(GET_MEETING_BY_ID, {
        id: this.$route.params.id,
      });
    },

    setProject(value) {
      const project = this.projects.find((project) => project.code === value);

      if (project) {
        this.form.project.nickname = project.nickname;
      } else {
        this.form.project.nickname = null;
      }
    },

    save() {
      const form = JSON.parse(JSON.stringify(this.form));

      if (!this.form.project.code) {
        form.project = null;
      }

      this.$store
        .dispatch(UPDATE_MEETING, {
          id: this.$route.params.id,
          ...form,
        })
        .then(() => {
          this.toIndexPage();
        });
    },

    inviteParticipant() {
      this.$store.dispatch(INVITE_PARTICIPANT, {
        id: this.$route.params.id,
        code: this.form.code,
      });
    },

    toIndexPage() {
      this.$router.push({
        name: "meeting",
      });
    },

    addParticipant() {
      this.attendant.push({
        id: null,
        name: null,
        position: null,
        email: null,
        signature: null,
        attend: null,
        department: {
          code: null,
          name: null,
        },
        division: {
          code: null,
          name: null,
        },
        meeting: {
          id: this.$route.params.id,
          code: this.form.code,
        },
      });
    },

    removeParticipant(index) {
      const deleteFunction = () => {
        //this.attendant.splice(index, 1);

        const attendants = JSON.parse(JSON.stringify(this.attendant));

        attendants[index].delete = 1;

        this.attendant = [];

        this.attendant = attendants;
      };

      deleteModal(deleteFunction);
    },

    editParticipant(item, index) {
      this.editIndex = index;

      Object.assign(this.selectedParticipant, item);
    },

    cancelEditParticipant() {
      this.editIndex = null;

      this.selectedParticipant = {
        id: null,
      };
    },

    isEdit(index) {
      return index === this.editIndex;
    },

    setDataByIndex(value, key, index, options) {
      const currentData = options.find((option) => option.value === value);

      this.attendant[index][key].name = currentData?.text;
    },

    saveParticipant() {
      this.$store.dispatch(SAVE_MEETING_PARTICIPANT, {
        form: this.form,
        attendant: this.attendant.map((item) => {
          return {
            ...item,
            meeting: {
              id: this.$route.params.id,
              code: this.form.code,
            },
          };
        }),
      });
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Change Meeting" }]);

    this.getProjects();

    this.getDepartments();

    this.getDivisions();

    this.getMeetingDetail();
  },
};
</script>
